import request from '@/utils/request'
// 设备结算日汇总 分页
export function deviceSettleDayPage(query) {
  return request({
    url: '/wisdom/wisdom-device-settle-day/page',
    method: 'get',
    params: query
  })
}

// 设备结算日汇总 导出
export function deviceSettleDayExport(data) {
  return request({
    url: '/wisdom/wisdom-device-settle-day/export',
    method: 'post',
    data
  })
}
